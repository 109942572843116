.autocomplete{
    margin: 2% !important;
}
.requestH1{
    text-align: center;
    font-weight: 600;
    /* background-color: orange; */
    color: rgb(85, 82, 82);
  
}
.showEmail{
    position: relative;
    left: 30%;
}
.showEmail >*{
    margin:0.5%
}
.reqNum  {
    margin: 2% !important;
  position: relative;
left: 30% !important;
}
.secButton{
    margin: 2% !important;
    position: relative;
  left: 40% !important; 
  /* background-color: orange !important; */
  /* color: aliceblue !important; */
  border: solid aliceblue !important ;
}
.addEmailButton{
    margin: 2% !important;
    position: relative;
  left: 30% !important; 
  /* background-color: orange !important; */
  /* color: aliceblue !important; */
  border: solid aliceblue !important ;
} 